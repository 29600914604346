<template>
  <div class="container-fluid table-wrapper mt-3">
    <div class="d-flex align-items-sm-stretch align-items-end justify-content-between flex-wrap">
      <div class="d-flex align-items-lg-center mb-1 flex-wrap  flex-sm-row flex-column justify-content-between flex-grow-1">

        <Breadcrumb current_page="جميع الأخبار"/>
        <router-link to="/add-news" class="el-button el-button--success el-button--default btn-add ms-2 ">
          إضافة خبر جديد
        </router-link>
      </div>
      <collapse-filter>
        <form action="" class="d-flex gap-3  flex-wrap filter-form">
          <div class="form-group">
            <label>بحث بحسب ال id</label>
            <input type="text" v-model="id" class="form-control search_by_id"/>
          </div>

          <div class="form-group">
            <label>العنوان</label>
            <input type="text" v-model="title" class="form-control"/>
          </div>

          <div class="form-group">
            <label class="d-block">التصنيف</label>
            <el-select v-model="category_id" filterable placeholder="Select">
              <el-option-group
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.name"
              >
                <el-option
                    v-for="subCategory in item.children"
                    :key="subCategory.id"
                    :label="subCategory.name"
                    :value="subCategory.id"
                >
                </el-option>
              </el-option-group>

            </el-select>
          </div>

          <div class="form-group">
            <label>التاريخ</label>
            <v-date-picker v-model="dateRange" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                  <input
                      :value="`${inputValue.start} - ${inputValue.end}`"
                      v-on="inputEvents.start"
                      class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>

          <div class="form-group align-self-end ">
            <button class="btn btn-search px-3" type="button" @click="handleSearch" :disabled="loading">
              بحث
            </button>
            <button class="btn btn-warning btn-reset px-2 mx-3" type="reset" @click="resetSearch">
              reset
            </button>
          </div>
        </form>
      </collapse-filter>
    </div>

    <el-card class=" mt-2  overflow-auto">
      <el-table id="top-table" :data="news_list" class="mb-4" stripe style="width: 100%"
                max-height="850" v-loading="loading">
        <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>
        <el-table-column prop="title" width="480" class-name="text-end" label="عنوان الخبر">
          <template #default="scope">
            <a :href="scope.row.url" :title="scope.row.title" class="news-link" target="_blank">
              <span v-if="scope.row.is_draft" class="text-danger ms-2">[مسودة]</span>
              {{ scope.row.title }}
            </a>
          </template>
        </el-table-column>

        <el-table-column prop="no_of_views" width="100" label="المشاهدات"/>

        <el-table-column label="الناشر" width="130">
          <template #default="scope">
            {{ scope.row.publisher.display_name }}
          </template>
        </el-table-column>

        <el-table-column prop="category" width="200" class-name="px-3" label="التصنيف">
          <template #default="scope">
            {{ scope.row.category.name }}
          </template>
        </el-table-column>

        <el-table-column class-name="px-3" width="180" label="التاريخ">
          <template #default="scope">
            <div dir="ltr" class="text-end">
              {{
                      publishDate(scope.row.date_to_publish) ? publishDate(scope.row.date_to_publish) : publishDate(scope.row.created_at)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="الأدوات" width="120" class-name="control-btns">
          <template #default="scope">
            <el-tooltip class="item" effect="dark" content="تعديل الخبر" placement="top">
              <router-link :to="`/edit-news/${scope.row.id}`" class="py-1 px-2 btn btn-outline-primary">
                <i class="far fa-edit"/>
              </router-link>
            </el-tooltip>

            <el-popconfirm
                v-if="!scope.row.is_draft"
                hide-icon
                confirmButtonType="danger"
                cancelButtonType="default"
                confirmButtonText="نعم"
                cancelButtonText="لا"
                iconColor="red"
                @confirm="confirmDeactivate(scope.row.id)"
                title="هل أنت متأكد أنك تريد تعطيل هذا الخبر؟">
              <template #reference>
                <el-button :title="`${scope.row.is_disabled ? 'تفعيل الخبر' : 'تعطيل الخبر'}`" class="py-1 px-2 btn"
                           :class="`${scope.row.is_disabled ? 'btn-outline-secondary' : 'btn-outline-warning'}`">
                  <i class="las la-ban" style="font-size: 1.25em"/>
                </el-button>
              </template>
            </el-popconfirm>

            <el-popconfirm
                hide-icon
                confirmButtonType="danger"
                cancelButtonType="default"
                confirmButtonText="نعم"
                cancelButtonText="لا"
                iconColor="red"
                @confirm="confirmDelete(scope.row.id)"
                title="هل أنت متأكد أنك تريد حذف هذا الخبر؟">
              <template #reference>
                <el-button title="حذف الخبر" class="py-1 px-2 btn btn-outline-danger">
                  <i class="far fa-trash-alt"/>
                </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>

      </el-table>

      <pagination v-if="news_list.length" v-model="paginationSetup.page" :records="paginationSetup.total"
                  :per-page="paginationSetup.per_page"
                  :options="paginationOptions" @paginate="getAllNews"/>

    </el-card>


  </div>
</template>

<script setup>
import {onMounted, ref, reactive, computed} from "vue";

import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {useToast} from "vue-toastification";

import Loader from "@/components/Loader";
import Breadcrumb from "@/components/Breadcrumb";

import {
  deactivateNews,
  deleteSingleNews,
  fetchAllNews,
  searchNews
} from "@/services/news";

import {dateFormatter} from "@/utils/formatDate";
import CollapseFilter from "@/components/CollapseFilter";

const store  = useStore();
const router = useRouter();
const toast  = useToast();

const loading     = ref(true)
const news_list   = ref([])
const publishDate = computed(() => date =>  dateFormatter(date))

const paginationSetup = reactive(
    {
      page    : null,
      total   : null,
      per_page: null
    }
)

const id          = ref('')
const category_id = ref('')
const title       = ref('')

const date_from = ref('')
const date_to   = ref('')
const dateRange = computed(
    {
      get()
      {
        return {
          start: new Date(),
          end  : new Date()
        }
      },
      set(newValue)
      {
        date_from.value = newValue.start
        date_to.value   = newValue.end
      }
    }
)

const paginationOptions = {
  chunk    : 5,
  theme    : "bootstrap4",
  hideCount: true

}

const categories = computed(() => store.getters.categories)

const getAllNews = () => {
  fetchAllNews(paginationSetup.page || 1).then(res => {
    loading.value            = false
    paginationSetup.page     = res.data.current_page;
    paginationSetup.total    = res.data.total;
    paginationSetup.per_page = res.data.per_page;
    news_list.value          = res.data.data;
    let topOfElement         = document.querySelector('#top-table').offsetTop;
    window.scroll({top: topOfElement, behavior: "smooth"});
  }).catch(() => {
    loading.value = false
  })
}

onMounted(() => {
  store.dispatch('fetchCategories')
  getAllNews();
})

const resetSearch  = () => {
  category_id.value = '';
  id.value          = '';
  title.value       = '';
}
const handleSearch = () => {
  let searchObj = {
    category_id: category_id.value,
    id         : id.value,
    title      : title.value,
    from_date  : date_from.value.toLocaleString("en-US"),
    to_date    : date_to.value.toLocaleString("en-US")
  }

  loading.value = true

  searchNews(searchObj)
      .then(result => {
        loading.value            = false
        news_list.value          = result.data.data
        paginationSetup.page     = result.data.current_page;
        paginationSetup.total    = result.data.total;
        paginationSetup.per_page = result.data.per_page;
      })
      .catch(() => loading.value = false)
}


const confirmDelete = id => (
    deleteSingleNews(id)
        .then(() => {
          let newsToDelete = news_list.value.findIndex(news => news.id === id)
          news_list.value.splice(newsToDelete, 1);
          toast.success("تم الحذف بنجاح");
        })
        .catch(() => {
          toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى");
        })
)

const confirmDeactivate = id => (
    deactivateNews(id)
        .then(() => {
          let articleToDisable         = news_list.value.find(article => article?.id === id)
          articleToDisable.is_disabled = !articleToDisable.is_disabled
          toast.success("تم التعطيل بنجاح");
        })
        .catch(() => {
          toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى");
        })
)
</script>

<style lang="scss">
td a {
  color: #212529;
  text-decoration: none;
}

.filter-form {
  input,
  select {
    min-width: 250px;
    height: 40px;
  }
}


.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}


.custom-width {
  min-width: 300px;
}


</style>
